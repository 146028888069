<template>
  <base-layout>
    <ion-content class="has-header has-subheader">
      <ion-list>
        <div v-for="page in pages" :key="page.id">
          <router-link :to="'/app/generalinfo/' + page.id">
            <ion-item detail>
              <ion-label>{{ page.title }}</ion-label>
            </ion-item>
          </router-link>
        </div>
      </ion-list>
    </ion-content>
  </base-layout>
</template>
<script>
import {IonContent, IonItem, IonLabel} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions} from "vuex";

export default defineComponent({
  name: "Subpages",
  data() {
    return {
      pages: []
    };
  },
  components: {
    IonContent,
    IonItem,
    IonLabel
  },
  methods: {
    ...mapActions("subpages", ["getAllPages"]),
    ...mapActions('googleanalytics', ['trackWithLabel']),


  },

  async created() {
    this.pages = await this.getAllPages();
    this.trackWithLabel('General Info')


  }
});
</script>
<style lang="scss" scoped>
a {
  text-decoration: none
}
</style>
